<template>
  <div class="form-owner-profile">
    <bg-text size="heading-2" class="mb-32">{{ xPropertyDetail.name }}</bg-text>
    <bg-text size="heading-4" class="mb-32"> Profil Pemilik </bg-text>

    <ValidationObserver ref="observer">
      <bg-grid>
        <bg-grid-item :col="6" class="mb-32">
          <ValidationProvider
            name="Nama Pemilik"
            rules="required"
            v-slot="{ failedRules }"
          >
            <bg-field
              label="Nama Pemilik"
              class="mb-0"
              :error="!!failedRules.required"
              :message="failedRules.required"
            >
              <bg-skeleton v-if="loadingFetchData" width="100%" :height="50" />
              <bg-input
                v-else
                v-model="owner.name"
                placeholder="John Doe"
                data-testid="input-name"
              />
            </bg-field>
          </ValidationProvider>
        </bg-grid-item>

        <bg-grid-item :col="6" class="mb-32">
          <ValidationProvider
            name="Nomor HP"
            rules="required|numeric|phone|min:8|max:14"
            v-slot="{ errors }"
          >
            <bg-field
              label="Nomor HP"
              class="mb-0"
              :error="!!errors.length"
              :message="errors[0]"
            >
              <bg-skeleton v-if="loadingFetchData" width="100%" :height="50" />
              <bg-input
                v-else
                v-model="owner.phone_number"
                placeholder="Masukkan nomor HP"
                data-testid="input-phone-number"
              />
            </bg-field>
          </ValidationProvider>
        </bg-grid-item>

        <bg-grid-item :col="12" class="mb-32">
          <ValidationProvider
            name="Alamat"
            rules="required|address"
            v-slot="{ errors }"
          >
            <bg-field
              label="Alamat"
              class="mb-0"
              :error="!!errors.length"
              :message="errors[0]"
            >
              <bg-skeleton v-if="loadingFetchData" width="100%" :height="80" />
              <bg-textarea
                v-else
                v-model="owner.address"
                placeholder="Masukkan Alamat"
                :has-counter="false"
                data-testid="input-address"
              />
            </bg-field>
          </ValidationProvider>
        </bg-grid-item>

        <bg-grid-item :col="6" class="mb-32">
          <ValidationProvider
            name="Provinsi"
            rules="required"
            v-slot="{ errors }"
          >
            <bg-field
              label="Provinsi"
              class="mb-0"
              :error="!!errors.length"
              :message="errors[0]"
            >
              <bg-skeleton
                v-if="loadingFetchData || area.loadingFetchProvince"
                width="100%"
                :height="50"
              />
              <bg-select
                v-else
                v-model="owner.province"
                placeholder="Pilih Provinsi di sini"
                :options="area.provinces"
                @change="provinceChange"
                data-testid="input-province"
              />
            </bg-field>
          </ValidationProvider>
        </bg-grid-item>

        <bg-grid-item :col="6" class="mb-32">
          <ValidationProvider
            name="Kota/Kabupaten"
            rules="required"
            v-slot="{ errors }"
          >
            <bg-field
              label="Kota/Kabupaten"
              class="mb-0"
              :error="!!errors.length"
              :message="errors[0]"
            >
              <bg-skeleton
                v-if="loadingFetchData || area.loadingFetchCity"
                width="100%"
                :height="50"
              />
              <bg-select
                v-else
                v-model="owner.city"
                placeholder="Pilih Kota/Kabupaten di sini"
                :disabled="!owner.province || !area.provinces.length"
                :options="area.cities"
                @change="cityChange"
                data-testid="input-city"
              />
            </bg-field>
          </ValidationProvider>
        </bg-grid-item>

        <bg-grid-item :col="6" class="mb-32">
          <ValidationProvider
            name="Kecamatan"
            rules="required"
            v-slot="{ errors }"
          >
            <bg-field
              label="Kecamatan"
              class="mb-0"
              :error="!!errors.length"
              :message="errors[0]"
            >
              <bg-skeleton
                v-if="loadingFetchData || area.loadingFetchSubdistrict"
                width="100%"
                :height="50"
              />
              <bg-select
                v-else
                v-model="owner.subdistrict"
                placeholder="Pilih Kecamatan di sini"
                :disabled="!owner.city || !area.cities.length"
                :options="area.subdistricts"
                @change="subdistrictChange"
                data-testid="input-subdistrict"
              />
            </bg-field>
          </ValidationProvider>
        </bg-grid-item>

        <bg-grid-item :col="6" class="mb-32">
          <ValidationProvider
            name="Kelurahan"
            rules="required"
            v-slot="{ errors }"
          >
            <bg-field
              label="Kelurahan"
              class="mb-0"
              :error="!!errors.length"
              :message="errors[0]"
            >
              <bg-skeleton
                v-if="loadingFetchData || area.loadingFetchVillage"
                width="100%"
                :height="50"
              />
              <bg-select
                v-else
                v-model="owner.village"
                placeholder="Pilih kelurahan di sini"
                :options="area.villages"
                :disabled="!owner.subdistrict || !area.subdistricts.length"
                data-testid="input-village"
              />
            </bg-field>
          </ValidationProvider>
        </bg-grid-item>
      </bg-grid>
    </ValidationObserver>

    <bg-grid class="mt-32">
      <bg-grid-item :col="12">
        <bg-button
          variant="primary"
          :disabled="loadingFetchData"
          @click="checkingAllForm"
        >
          Simpan
        </bg-button>
      </bg-grid-item>
    </bg-grid>

    <bg-modal
      v-model="isConfirmModalOpen"
      desktop-size="sm"
      title="Yakin menyimpan perubahan?"
      description="Mohon persiapkan dokumen pendukung yang dibutuhkan."
      :close-on-click-backdrop="!loadingSaveData"
      data-testid="modal-confirmation"
    >
      <template v-slot:footer>
        <div class="flex align-center justify-end">
          <bg-button
            class="mr-16"
            @click="isConfirmModalOpen = false"
            :disabled="loadingSaveData"
          >
            Batal
          </bg-button>
          <bg-button
            @click="handleSaveChanges"
            variant="primary"
            :loading="loadingSaveData"
          >
            Simpan
          </bg-button>
        </div>
      </template>
    </bg-modal>
  </div>
</template>

<script>
import propertyApi from '@admin_endpoints/property-detail';
import { mapState, mapMutations } from 'vuex';

import {
  BgGrid,
  BgGridItem,
  BgInput,
  BgField,
  BgText,
  BgSelect,
  BgButton,
  BgModal,
  BgTextarea,
  BgSkeleton,
} from 'bangul-vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import areaApi from '@admin_endpoints/area';
import contractManagementApi from '@admin_endpoints/contract-management';
import './veeValidateCustomRules';

export default {
  name: 'FormOwnerProfile',

  components: {
    BgGrid,
    BgGridItem,
    BgInput,
    BgField,
    BgText,
    BgSelect,
    BgButton,
    BgModal,
    BgTextarea,
    BgSkeleton,
    ValidationObserver,
    ValidationProvider,
  },

  data() {
    return {
      loadingFetchData: false,
      loadingSaveData: false,
      isConfirmModalOpen: false,
      owner: {
        name: '',
        phone_number: '',
        address: '',
        province: '',
        city: '',
        subdistrict: '',
        village: '',
      },
      area: {
        provinces: [],
        cities: [],
        subdistricts: [],
        villages: [],
        loadingFetchProvince: false,
        loadingFetchCity: false,
        loadingFetchSubdistrict: false,
        loadingFetchVillage: false,
      },
    };
  },

  computed: {
    ...mapState('propertyDetail', ['xPropertyDetail']),
    propertyId() {
      return this.$route.params.propertyId;
    },
  },

  created() {
    this.handleUpdateBreadcrumb();
    this.fetchOwnerData();
  },

  methods: {
    ...mapMutations('breadcrumb', ['updateBreadcrumb']),

    handleUpdateBreadcrumb() {
      this.updateBreadcrumb({
        index: 1,
        item: {
          name: this.xPropertyDetail.name,
          url: `/property-detail/${this.propertyId}/contract`,
        },
      });
    },

    async fetchOwnerData() {
      this.loadingFetchData = true;

      try {
        const { data } = await propertyApi.getOwnerProfile(this.propertyId);

        if (data.status) {
          this.owner = this.updateOwnerData(data.data, this.owner);
          await this.fetchProvinces();

          if (this.owner.province) {
            const matchProvince = this.area.provinces.find(
              province => province.label === this.owner.province
            );

            if (matchProvince) {
              this.owner.province = matchProvince.val;
              this.provinceChange(matchProvince.val, true);
            }
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingFetchData = false;
      }
    },

    updateOwnerData(data, currentData) {
      return Object.entries(data).reduce((acc, [key, val]) => {
        acc[key] = val === '-' ? '' : val;
        return acc;
      }, currentData);
    },

    async checkingAllForm() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) this.isConfirmModalOpen = true;
    },

    async handleSaveChanges() {
      let isError = false;
      this.loadingSaveData = true;

      const ownerData = {
        name: this.owner.name,
        phone_number: this.owner.phone_number,
        address: this.owner.address,
        province_id: this.owner.province,
        city_id: this.owner.city,
        subdistrict_id: this.owner.subdistrict,
        village_id: this.owner.village,
      };

      try {
        const { data } = await contractManagementApi.updateOwnerProfile(
          this.propertyId,
          ownerData
        );

        if (!data.status) {
          isError = true;
        }
      } catch (error) {
        console.error(error);
        isError = true;
      } finally {
        this.loadingSaveData = false;
      }

      if (isError) {
        this.$toast.show('Terjadi kesalahan silahkan coba lagi.');
      } else {
        this.isConfirmModalOpen = false;
        this.$toast.show('Perubahan berhasil disimpan.');
        this.$router.replace({
          name: 'property-detail.contract',
          params: {
            propertyId: this.propertyId,
          },
        });
      }
    },

    async fetchProvinces() {
      this.area.loadingFetchProvince = true;
      try {
        const { data } = await areaApi.getProvinces();
        if (data.status) {
          this.area.provinces = data.data.map(d => ({
            val: d.id,
            label: d.name,
          }));
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.area.loadingFetchProvince = false;
      }
    },

    async fetchCityData(provinceId) {
      this.area.loadingFetchCity = true;
      try {
        const { data } = await areaApi.getCities(provinceId);
        if (data.status) {
          this.area.cities = data.data.map(d => ({
            val: d.id,
            label: d.name,
          }));
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.area.loadingFetchCity = false;
      }
    },

    async fetchSubdistrictData(cityId) {
      this.area.loadingFetchSubdistrict = true;
      try {
        const { data } = await areaApi.getSubdistricts(cityId);
        if (data.status) {
          this.area.subdistricts = data.data.map(d => ({
            val: d.id,
            label: d.name,
          }));
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.area.loadingFetchSubdistrict = false;
      }
    },

    async fetchVillageData(subdistrictId) {
      this.area.loadingFetchVillage = true;
      try {
        const { data } = await areaApi.getVillages(subdistrictId);
        if (data.status) {
          this.area.villages = data.data.map(d => ({
            val: d.id,
            label: d.name,
          }));
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.area.loadingFetchVillage = false;
      }
    },

    async provinceChange(provinceId, isFirstInit = false) {
      const prevSelectedCity = this.owner.city;
      this.owner.city = '';
      this.area.cities = [];

      if (!isFirstInit) {
        this.owner.subdistrict = '';
        this.owner.village = '';
        this.area.subdistricts = [];
        this.area.villages = [];
        this.$refs.observer.reset();
      }

      await this.fetchCityData(provinceId);

      if (isFirstInit && prevSelectedCity) {
        const matchCity = this.area.cities.find(
          city => city.label === prevSelectedCity
        );

        if (matchCity) {
          this.owner.city = matchCity.val;
          this.cityChange(matchCity.val, true);
        }
      }
    },

    async cityChange(cityId, isFirstInit = false) {
      const prevSelectedSubdistrict = this.owner.subdistrict;
      this.owner.subdistrict = '';
      this.area.subdistricts = [];

      if (!isFirstInit) {
        this.owner.village = '';
        this.area.villages = [];
        this.$refs.observer.reset();
      }

      await this.fetchSubdistrictData(cityId);

      if (isFirstInit && prevSelectedSubdistrict) {
        const matchSubdistrict = this.area.subdistricts.find(
          subdistrict => subdistrict.label === prevSelectedSubdistrict
        );

        if (matchSubdistrict) {
          this.owner.subdistrict = matchSubdistrict.val;
          this.subdistrictChange(matchSubdistrict.val, true);
        }
      }
    },

    async subdistrictChange(subdistrictId, isFirstInit = false) {
      const prevSelectedVillage = this.owner.village;
      this.owner.village = '';
      this.area.villages = [];
      this.$refs.observer.reset();
      await this.fetchVillageData(subdistrictId);

      if (isFirstInit && prevSelectedVillage) {
        const matchVillage = this.area.villages.find(
          village => village.label === prevSelectedVillage
        );

        if (matchVillage) {
          this.owner.village = matchVillage.val;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./FormOwnerProfile.scss"></style>
