<template>
  <div class="form-terminate-contract">
    <bg-text size="heading-2" class="mb-32">{{ xPropertyDetail.name }}</bg-text>
    <bg-text size="heading-4"> Akhiri kontrak properti </bg-text>
    <bg-text size="body-2" class="mb-32">
      Silakan pilih tanggal dan alasan kontrak properti diakhiri.
    </bg-text>

    <ValidationObserver ref="observer">
      <bg-grid>
        <bg-grid-item :col="6" class="mb-32">
          <bg-field label="Tanggal Kontrak Berakhir" class="mb-0">
            <bg-skeleton v-if="loadingFetchData" width="100%" :height="50" />
            <bg-datepicker
              v-else
              v-model="today"
              placeholder="Masukkan tanggal berakhir"
              format="dd MMMM yyyy"
              disabled
            />
          </bg-field>
        </bg-grid-item>
      </bg-grid>

      <bg-grid>
        <bg-grid-item :col="6" class="mb-32">
          <ValidationProvider
            name="Alasan"
            rules="required"
            v-slot="{ errors }"
          >
            <bg-field
              label="Alasan"
              class="mb-0"
              :error="!!errors.length"
              :message="errors[0]"
            >
              <bg-skeleton v-if="loadingFetchData" width="100%" :height="50" />
              <bg-select
                v-else
                v-model="form.reason"
                :options="reasonOptions"
                placeholder="Pilih alasan kontrak berakhir"
                data-testid="input-reason"
              />
            </bg-field>
          </ValidationProvider>
        </bg-grid-item>
      </bg-grid>

      <bg-grid>
        <bg-grid-item :col="6" class="mb-32">
          <ValidationProvider
            name="Catatan"
            :rules="{ required_note: form.reason === 'other' }"
            v-slot="{ errors }"
          >
            <bg-field
              label="Catatan"
              class="mb-0"
              :error="!!errors.length"
              :message="errors[0]"
            >
              <bg-skeleton v-if="loadingFetchData" width="100%" :height="80" />
              <bg-textarea
                v-else
                v-model="form.note"
                placeholder="Tulis catatan di sini"
                :max-char="255"
              />
            </bg-field>
          </ValidationProvider>
        </bg-grid-item>
      </bg-grid>
    </ValidationObserver>

    <bg-grid class="mt-32">
      <bg-grid-item :col="12">
        <bg-button
          variant="primary"
          :disabled="loadingFetchData"
          @click="checkingAllForm"
        >
          Simpan dan Akhiri Kontrak
        </bg-button>
      </bg-grid-item>
    </bg-grid>

    <bg-modal
      v-model="isConfirmModalOpen"
      desktop-size="sm"
      title="Yakin akhiri kontrak properti?"
      description="Setelah kontrak diakhiri, mohon hubungi tim terkait untuk menyelesaikan proses pengakhiran kontrak properti."
      :close-on-click-backdrop="!loadingTerminateContract"
    >
      <template v-slot:footer>
        <div class="flex align-center justify-end">
          <bg-button
            class="mr-16"
            @click="isConfirmModalOpen = false"
            :disabled="loadingTerminateContract"
          >
            Batal
          </bg-button>
          <bg-button
            @click="terminateContract"
            variant="primary"
            :loading="loadingTerminateContract"
          >
            Akhiri Kontrak
          </bg-button>
        </div>
      </template>
    </bg-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  BgGrid,
  BgGridItem,
  BgField,
  BgText,
  BgSelect,
  BgButton,
  BgModal,
  BgTextarea,
  BgSkeleton,
  BgDatepicker,
} from 'bangul-vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import formApi from '@admin_endpoints/forms';
import './veeValidateCustomRules';

export default {
  name: 'FormTerminateContract',

  components: {
    BgGrid,
    BgGridItem,
    BgField,
    BgText,
    BgSelect,
    BgButton,
    BgModal,
    BgTextarea,
    BgSkeleton,
    BgDatepicker,
    ValidationObserver,
    ValidationProvider,
  },

  data() {
    return {
      loadingTerminateContract: false,
      loadingFetchData: false,
      isConfirmModalOpen: false,
      today: new Date(),
      form: {
        reason: '',
        note: '',
      },
      reasonOptions: [
        {
          val: 'uncooperative_owner',
          label: 'Pemilik tidak kooperatif',
        },
        {
          val: 'fraudulent_owner',
          label: 'Pemilik melakukan kecurangan',
        },
        {
          val: 'unprofitable_property',
          label: 'Properti tidak menguntungkan',
        },
        {
          val: 'other',
          label: 'Lainnya',
        },
      ],
    };
  },

  computed: {
    ...mapState('propertyDetail', ['xPropertyDetail']),
    propertyId() {
      return this.$route.params.propertyId;
    },
    contractId() {
      return this.$route.params.contractId;
    },
  },

  methods: {
    async checkingAllForm() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) this.isConfirmModalOpen = true;
    },

    async terminateContract() {
      let errorMessage = '';
      this.loadingTerminateContract = true;

      try {
        await formApi.terminateContract(this.contractId, this.form);
      } catch (error) {
        const errorDetail = error?.response.data?.issue?.details || {};

        if (Object.keys(errorDetail).length) {
          errorMessage = Object.entries(errorDetail)[0][1][0];
        } else {
          errorMessage = 'Terjadi kesalahan silahkan coba lagi.';
        }
      } finally {
        this.loadingTerminateContract = false;
        this.isConfirmModalOpen = false;
      }

      if (!errorMessage) {
        this.$toast.show('Kontrak properti sudah diakhiri.');
        this.$router.replace({
          name: 'property-detail.contract',
          params: {
            propertyId: this.propertyId,
          },
        });
      } else {
        this.$toast.show(errorMessage);
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./FormTerminateContract.scss"></style>
