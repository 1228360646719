<template>
  <div class="form-disbursement-information">
    <bg-text size="heading-2" class="mb-32">{{ xPropertyDetail.name }}</bg-text>
    <bg-text size="heading-4" class="mb-32"
      >Informasi Transfer Pendapatan</bg-text
    >

    <template v-if="loading.disbursementPartnership">
      <bg-grid>
        <bg-grid-item :col="6" class="mb-32">
          <bg-skeleton width="120px" height="16px" class="mb-8" />
          <bg-skeleton width="100%" height="48px" />
        </bg-grid-item>

        <bg-grid-item :col="6" class="mb-32">
          <bg-skeleton width="120px" height="16px" class="mb-8" />
          <bg-skeleton width="100%" height="48px" />
        </bg-grid-item>

        <bg-grid-item :col="6" class="mb-32">
          <bg-skeleton width="120px" height="16px" class="mb-8" />
          <bg-skeleton width="100%" height="48px" />
        </bg-grid-item>

        <bg-grid-item :col="6" class="mb-32">
          <bg-skeleton width="120px" height="16px" class="mb-8" />
          <bg-skeleton width="100%" height="48px" />
        </bg-grid-item>
      </bg-grid>
    </template>

    <template v-else>
      <bg-grid>
        <bg-grid-item :col="6" class="mb-32">
          <bg-field
            label="Nomor Rekening"
            class="mb-0"
            :message="error.message.accountNumber"
            :error="error.show.accountNumber"
          >
            <bg-input
              v-model="values.bank_account_number"
              @input="onlyNumber"
            />
          </bg-field>
        </bg-grid-item>

        <bg-grid-item :col="6" class="mb-32">
          <bg-field label="Nama Bank" class="mb-0">
            <bg-select
              v-model="values.bank_name"
              :options="options.banks"
              searchable
            />
          </bg-field>
        </bg-grid-item>

        <bg-grid-item :col="6" class="mb-32">
          <bg-field label="Cabang Bank (Opsional)" class="mb-0">
            <bg-input v-model="values.bank_account_city" />
          </bg-field>
        </bg-grid-item>

        <bg-grid-item :col="6" class="mb-32">
          <bg-field
            label="Nama Pemilik Rekening"
            class="mb-0"
            :message="error.message.accountOwner"
            :error="error.show.accountOwner"
          >
            <bg-input v-model="values.bank_account_owner" />
          </bg-field>
        </bg-grid-item>

        <bg-grid-item :col="6" class="mb-32">
          <bg-field
            label="Tanggal Transfer"
            description="Perubahan akan diterapkan pada periode transfer berikutnya"
            class="mb-0"
          >
            <bg-select
              v-model="values.transfer_date"
              :options="options.transferDate"
            />
          </bg-field>
        </bg-grid-item>
      </bg-grid>

      <bg-grid class="button-container">
        <bg-grid-item :col="12">
          <bg-button
            variant="primary"
            @click="showModal = true"
            :disabled="disabled"
            >Simpan</bg-button
          >
        </bg-grid-item>
      </bg-grid>
    </template>

    <bg-modal
      v-model="showModal"
      desktop-size="sm"
      title="Yakin menyimpan perubahan?"
      description="Mohon persiapkan dokumen pendukung yang dibutuhkan."
    >
      <template v-slot:footer>
        <div class="button-modal">
          <bg-button
            class="mr-16"
            @click="showModal = false"
            :disabled="loading.save"
            >Batal</bg-button
          >
          <bg-button
            @click="handleSaveChanges"
            variant="primary"
            :loading="loading.save"
            >Simpan</bg-button
          >
        </div>
      </template>
    </bg-modal>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import formsApi from '@admin_endpoints/forms';
import propertyApi from '@admin_endpoints/property-detail';

import {
  BgGrid,
  BgGridItem,
  BgInput,
  BgField,
  BgText,
  BgSelect,
  BgButton,
  BgModal,
  BgSkeleton,
} from 'bangul-vue';

export default {
  name: 'FormDisbursementInformation',

  components: {
    BgGrid,
    BgGridItem,
    BgInput,
    BgField,
    BgText,
    BgSelect,
    BgButton,
    BgModal,
    BgSkeleton,
  },

  computed: {
    ...mapState('propertyDetail', ['xPropertyDetail']),
    propertyId() {
      return this.$route.params.propertyId;
    },
    disabled() {
      return !(
        this.values.bank_account_number &&
        this.values.bank_name &&
        this.values.bank_account_owner &&
        this.values.transfer_date
      );
    },
  },

  data() {
    return {
      loading: {
        disbursementPartnership: true,
        save: false,
      },
      showModal: false,
      transferInfo: {},
      errorMessages: {
        onlyNumber: 'Nomor rekening hanya dapat diisi dengan angka.',
        ownerRequired: 'Masukkan nama pemilik rekening,',
      },
      error: {
        message: {
          accountNumber: '',
          accountOwner: '',
        },
        show: {
          accountNumber: false,
          accountOwner: false,
        },
      },
      banks: {},
      options: {
        banks: {},
        transferDate: [
          {
            val: 5,
            label: '5',
          },
          {
            val: 20,
            label: '20',
          },
        ],
      },
      values: {},
    };
  },

  created() {
    this.handleUpdateBreadcrumb();
    this.consumeDisbursementAndPartnership();
  },

  methods: {
    ...mapMutations('breadcrumb', ['updateBreadcrumb']),

    handleUpdateBreadcrumb() {
      this.updateBreadcrumb({
        index: 1,
        item: {
          name: this.xPropertyDetail.name,
          url: `/property-detail/${this.propertyId}/contract`,
        },
      });
    },

    async fetchDisbursementAndPartnership() {
      let result = {};

      try {
        result = await propertyApi.getDisbursementAndPartnership(
          this.propertyId
        );
      } catch (error) {
        result.hasError = true;
        console.error(error);
      }

      return result;
    },

    async fetchBankList() {
      let result = {};

      try {
        result = await formsApi.getBankList();
      } catch (error) {
        result.hasError = true;
        console.error(error);
      }

      return result;
    },

    async updateEditDisbursementInformation() {
      let result = {};
      try {
        result = await formsApi.putEditDisbursementInformation(
          this.propertyId,
          this.values
        );
      } catch (error) {
        result.hasError = true;
        console.error(error);
      }
      return result;
    },

    async consumeDisbursementAndPartnership() {
      this.loading.disbursementPartnership = true;

      const response = await this.fetchDisbursementAndPartnership();
      const bankList = await this.fetchBankList();

      if (!response.hasError) {
        const banks = bankList.data.data;
        this.transferInfo = response.data.data.transfer_info;

        this.values = {
          ...this.transferInfo,
          bank_name: this.getBankCode(this.transferInfo.bank_name, banks),
        };

        this.options.banks = banks.map(item => ({
          val: item.bank_code,
          label: item.bank_name,
        }));
      }

      if (!bankList.hasError) {
        this.banks = bankList.data.data;
      }

      this.loading.disbursementPartnership = false;
    },

    getBankCode(bankName, banks) {
      const matchBank = banks.find(bank => bank.bank_name === bankName);

      return matchBank?.bank_code || '';
    },

    async saveEditDisbursementInformation() {
      this.loading.save = true;
      const response = await this.updateEditDisbursementInformation();

      if (!response.hasError) {
        this.$toast.show('Perubahan berhasil disimpan.');
        this.$router.push(`/property-detail/${this.propertyId}/contract`);
      }

      this.loading.save = false;
    },

    handleSaveChanges() {
      this.saveEditDisbursementInformation();
    },

    onlyNumber(value) {
      this.values.bank_account_number = value.replace(/\D/g, '');
    },
  },
};
</script>

<style lang="scss" scoped src="./FormDisbursementInformation.scss"></style>
