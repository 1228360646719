import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
	...required,
	message: 'Masukkan {_field_}.',
});

extend('required_note', {
	...required,
	message: 'Jelaskan alasan pengakhiran kontrak properti',
});
