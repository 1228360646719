export const AsyncState = {
	INITIAL: 'initial',
	LOADING: 'loading',
	SUCCESS: 'success',
	NO_DATA: 'no_data',
	ERROR: 'error',
};

export const Messages = {
	noData: 'Belum ada data. Silakan tambah role.',
	error: 'Terjadi kesalahan saat mendapatkan data role.',
	toastError: 'Gagal saat mendapatkan data role, silahkan refresh halaman.',
	notFound: 'Role tidak ditemukan di kata kunci yang Anda gunakan.',
};
