<template>
  <div class="role-management">
    <bg-grid class="mb-32">
      <bg-grid-item :col="12">
        <bg-text :tag="constants.H3" :size="constants.HEADING_3">
          Role Management
        </bg-text>
      </bg-grid-item>

      <bg-grid-item :col="6" class="mb-0">
        <bg-grid>
          <bg-grid-item :col="8" class="mb-0">
            <bg-search-bar
              v-model="search"
              placeholder="Cari berdasarkan nama role"
              @keypress.enter="handleSearch"
            />
          </bg-grid-item>
          <bg-grid-item :col="3" class="mb-0">
            <bg-button size="lg" block @click="handleSearch"> Cari </bg-button>
          </bg-grid-item>
        </bg-grid>
      </bg-grid-item>

      <bg-grid-item :col="6" class="mb-0 ta-r">
        <bg-button size="lg" variant="secondary"> Member </bg-button>
        <bg-button
          v-if="accessAddRole"
          size="lg"
          variant="primary"
          class="ml-16"
          @click="$router.push('/role-management/roles/add')"
        >
          Tambah Role
        </bg-button>
      </bg-grid-item>
    </bg-grid>

    <table class="ss-table">
      <thead>
        <tr>
          <th
            v-for="(header, i) in headers"
            role="columnheader"
            :aria-label="header.text"
            :key="`header-${i}`"
            :class="header.classes"
          >
            <bg-text :size="constants.TITLE_5">{{ header.text }}</bg-text>
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="listAsyncStatus === AsyncState.LOADING">
          <tr v-for="i in 3" :key="`loading-tr-${i}`">
            <td v-for="(header, j) in headers" :key="header.text + j">
              <bg-skeleton :height="20" width="100%" />
            </td>
          </tr>
        </template>

        <template v-if="listAsyncStatus === AsyncState.SUCCESS">
          <tr v-for="(list, i) in lists" :key="`list-${i}`">
            <td>
              {{ list.name }}
            </td>
            <td>
              {{ list.permission_count }}
            </td>
            <td>
              {{ list.member_count }}
            </td>
            <td class="ta-c">
              <div class="ss-table__menu">
                <div
                  v-if="
                    list.id !== 1 &&
                    (accessEditRole || accessAssignRole || accessDeleteRole)
                  "
                  role="button"
                  class="menu-activator"
                  @click="toggleTableMenu(i)"
                >
                  <bg-icon
                    name="more-vertical"
                    title="open options for this item"
                  />
                </div>
                <div
                  v-if="list.showOption"
                  class="menu-options position-center"
                >
                  <bg-list-item
                    v-if="accessEditRole"
                    clickable
                    @click="handleGoToEditRole(list.id)"
                    >Edit</bg-list-item
                  >
                  <bg-list-item
                    v-if="accessAssignRole"
                    clickable
                    @click="goToMemberEdit(list.id)"
                    >Atur Member</bg-list-item
                  >
                  <bg-list-item
                    v-if="accessDeleteRole"
                    clickable
                    @click="openDeleteModal(list.id)"
                  >
                    Hapus
                  </bg-list-item>
                </div>
              </div>
            </td>
          </tr>
        </template>

        <template v-if="isShowTableMessage">
          <td class="no-data ta-c text-muted" colspan="4">
            {{ tableMessage }}
          </td>
        </template>
      </tbody>
    </table>

    <bg-pagination
      v-if="totalPage > 1"
      :value="pagination.page"
      :page-total="totalPage"
      @click="changePagination"
    />

    <delete-role-modal
      :is-open.sync="isDeleteModalOpen"
      :role-id.sync="selectedRoleId"
      @action-success-delete="handleSuccessDeleteRole"
      @action-fail-delete="openToast('Gagal saat menghapus role')"
    />
  </div>
</template>

<script>
import {
  BgGrid,
  BgGridItem,
  BgButton,
  BgSearchBar,
  BgText,
  BgIcon,
  BgListItem,
  BgPagination,
  BgSkeleton,
} from 'bangul-vue';
import { TEXT_SIZE, TEXT_TAG } from 'bangul-vue/src/utils/constants/text';
import RoleManagementApi from '@admin_endpoints/role-management';
import tableMenuHandler from '@admin/mixins/tableMenuHandler';
import { AsyncState, Messages } from './constants';

export default {
  name: 'RoleManagement',

  components: {
    BgGrid,
    BgGridItem,
    BgButton,
    BgSearchBar,
    BgText,
    BgIcon,
    BgListItem,
    BgPagination,
    BgSkeleton,
    DeleteRoleModal: () => import('./components/DeleteRoleModal'),
  },

  mixins: [tableMenuHandler],

  data() {
    return {
      search: '',
      constants: {
        H3: TEXT_TAG.H3,
        HEADING_3: TEXT_SIZE.HEADING_3,
        TITLE_5: TEXT_SIZE.TITLE_5,
      },
      headers: [
        {
          text: 'Nama Role',
          classes: 'ta-l',
        },
        {
          text: 'Jumlah Permission',
          classes: 'ta-l',
        },
        {
          text: 'Jumlah Member',
          classes: 'ta-l',
        },
        {
          text: 'Action',
        },
      ],
      lists: [],
      pagination: {
        page: 1,
        totalData: 0,
        limit: 20,
      },
      AsyncState,
      listAsyncStatus: AsyncState.INITIAL,
      tableMessage: '',
      isDeleteModalOpen: false,
      selectedRoleId: null,
    };
  },

  computed: {
    accessAddRole() {
      return this.$store.getters.xCheckUserPermission('add-role');
    },
    accessEditRole() {
      return this.$store.getters.xCheckUserPermission('edit-role');
    },
    accessDeleteRole() {
      return this.$store.getters.xCheckUserPermission('delete-role');
    },
    accessAssignRole() {
      return this.$store.getters.xCheckUserPermission('assign-role');
    },

    totalPage() {
      return Math.ceil(this.pagination.totalData / this.pagination.limit);
    },
    isShowTableMessage() {
      return [AsyncState.NO_DATA, AsyncState.ERROR].includes(
        this.listAsyncStatus
      );
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler({ query }) {
        this.handleRouteChange(query);
      },
    },
  },

  beforeMount() {
    if (this.$route.params.toastMessage) {
      this.openToast(this.$route.params.toastMessage);
    }
  },

  methods: {
    handleRouteChange({ page = 1, search = '' }) {
      this.pagination.page = Number.isFinite(Number(page)) ? +page : 1;
      this.search = search;

      const params = {
        limit: this.pagination.limit,
        offset: (this.pagination.page - 1) * this.pagination.limit,
      };

      if (this.search) params.q = this.search;

      this.fetchRoleData(params);
    },

    async fetchRoleData(params) {
      this.listAsyncStatus = AsyncState.LOADING;
      this.tableMessage = '';

      try {
        const { data } = await RoleManagementApi.getRoleList(params);

        if (data.status) {
          this.lists = this.mappingData(data.data);
          this.pagination.totalData = data.pagination.total_items;

          if (this.lists.length) {
            this.listAsyncStatus = AsyncState.SUCCESS;
          } else if (params.q) {
            this.tableMessage = Messages.notFound;
            this.listAsyncStatus = AsyncState.NO_DATA;
          } else {
            this.tableMessage = Messages.noData;
            this.listAsyncStatus = AsyncState.NO_DATA;
          }
        } else {
          this.tableMessage = Messages.error;
          this.openToast(Messages.toastError);
          this.listAsyncStatus = AsyncState.ERROR;
        }
      } catch (error) {
        console.error(error);
        this.tableMessage = Messages.error;
        this.openToast(Messages.toastError);
        this.listAsyncStatus = AsyncState.ERROR;
      }
    },

    mappingData(roles) {
      return roles.map(role => ({
        ...role,
        showOption: false,
      }));
    },

    changePagination(page) {
      this.$router
        .push({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            page,
          },
        })
        .catch(() => {});
    },

    handleSearch() {
      this.$router
        .push({
          name: this.$route.name,
          query: {
            page: 1,
            search: this.search,
          },
        })
        .catch(() => {});
    },

    openToast(message) {
      this.$toast.show(message, { duration: 5 });
    },

    handleGoToEditRole(roleId) {
      this.$router.push({
        name: 'role-management-roles.edit',
        params: {
          roleId,
        },
      });
    },

    goToMemberEdit(roleId = false) {
      if (roleId) {
        this.$router.push({
          name: 'role-management-member-edit',
          params: { roleId },
        });
      }
    },

    openDeleteModal(roleId) {
      this.selectedRoleId = roleId;
      this.isDeleteModalOpen = true;
    },

    handleSuccessDeleteRole() {
      const { page, limit } = this.pagination;
      if (page === 1) {
        this.fetchRoleData({ offset: 0, limit, q: this.search });
      } else if (page === this.totalPage && this.lists.length === 1) {
        this.changePagination(page - 1);
      } else {
        this.fetchRoleData({
          offset: (page - 1) * limit,
          limit,
          q: this.search,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./RoleManagement.scss"></style>
