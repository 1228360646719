import { extend } from 'vee-validate';
import { required, numeric, min, max } from 'vee-validate/dist/rules';

extend('required', {
	...required,
	message: 'Masukkan {_field_}.',
});

extend('numeric', {
	...numeric,
	message: '{_field_} hanya dapat diisi dengan angka.',
});

extend('phone', value => {
	if (/^(08\d+)$/.test(value)) {
		return true;
	}

	return '{_field_} harus diawali dengan 08.';
});

extend('min', {
	...min,
	message: '{_field_} minimal {length} karakter.',
});

extend('max', {
	...max,
	message: '{_field_} maksimal {length} karakter.',
});

extend('address', value => {
	if (/^[\w\s\d,.]+$/.test(value)) {
		return true;
	}

	return '{_field_} hanya boleh huruf, angka, spasi, titik dan koma';
});
