import api from '@admin/api/service';

export default {
	getProvinces() {
		return api.get('/singgahsini/api/area/province');
	},

	getCities(provinceId) {
		return api.get(`/singgahsini/api/area/province/${provinceId}/city`);
	},

	getSubdistricts(cityId) {
		return api.get(`/singgahsini/api/area/city/${cityId}/subdistrict`);
	},

	getVillages(subdistrictId) {
		return api.get(
			`/singgahsini/api/area/subdistrict/${subdistrictId}/village`
		);
	},
};
