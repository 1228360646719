import api from '@admin/api/service';

export default {
	putEditDisbursementInformation(propertyId, params) {
		return api.put(
			`/singgahsini/api/property/${propertyId}/transfer-info`,
			params
		);
	},

	putEditPartnershipDetail(propertyId, params) {
		return api.put(`/singgahsini/api/property/${propertyId}/contract`, params);
	},

	putEditAdditionalCost(propertyId, params) {
		return api.put(`/singgahsini/api/property/${propertyId}/additional-cost`, params)
	},

	postEditRenewContract(propertyId, params) {
		return api.post(`/singgahsini/api/property/${propertyId}/contract`, params);
	},

	getBankList() {
		return api.get('/singgahsini/api/bank');
	},

	getListProductType() {
		return api.get('/singgahsini/api/option/product-type');
	},

	getListRevenueModel() {
		return api.get('/singgahsini/api/option/revenue-model');
	},

	getListAddontype() {
		return api.get('/singgahsini/api/option/addon-type');
	},

	terminateContract(contractId, data) {
		return api.post(`/singgahsini/api/contract/${contractId}`, {
			...data,
			_method: 'DELETE',
		});
	},
};
