var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-owner-profile"},[_c('bg-text',{staticClass:"mb-32",attrs:{"size":"heading-2"}},[_vm._v(_vm._s(_vm.xPropertyDetail.name))]),_c('bg-text',{staticClass:"mb-32",attrs:{"size":"heading-4"}},[_vm._v(" Profil Pemilik ")]),_c('ValidationObserver',{ref:"observer"},[_c('bg-grid',[_c('bg-grid-item',{staticClass:"mb-32",attrs:{"col":6}},[_c('ValidationProvider',{attrs:{"name":"Nama Pemilik","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
return [_c('bg-field',{staticClass:"mb-0",attrs:{"label":"Nama Pemilik","error":!!failedRules.required,"message":failedRules.required}},[(_vm.loadingFetchData)?_c('bg-skeleton',{attrs:{"width":"100%","height":50}}):_c('bg-input',{attrs:{"placeholder":"John Doe","data-testid":"input-name"},model:{value:(_vm.owner.name),callback:function ($$v) {_vm.$set(_vm.owner, "name", $$v)},expression:"owner.name"}})],1)]}}])})],1),_c('bg-grid-item',{staticClass:"mb-32",attrs:{"col":6}},[_c('ValidationProvider',{attrs:{"name":"Nomor HP","rules":"required|numeric|phone|min:8|max:14"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('bg-field',{staticClass:"mb-0",attrs:{"label":"Nomor HP","error":!!errors.length,"message":errors[0]}},[(_vm.loadingFetchData)?_c('bg-skeleton',{attrs:{"width":"100%","height":50}}):_c('bg-input',{attrs:{"placeholder":"Masukkan nomor HP","data-testid":"input-phone-number"},model:{value:(_vm.owner.phone_number),callback:function ($$v) {_vm.$set(_vm.owner, "phone_number", $$v)},expression:"owner.phone_number"}})],1)]}}])})],1),_c('bg-grid-item',{staticClass:"mb-32",attrs:{"col":12}},[_c('ValidationProvider',{attrs:{"name":"Alamat","rules":"required|address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('bg-field',{staticClass:"mb-0",attrs:{"label":"Alamat","error":!!errors.length,"message":errors[0]}},[(_vm.loadingFetchData)?_c('bg-skeleton',{attrs:{"width":"100%","height":80}}):_c('bg-textarea',{attrs:{"placeholder":"Masukkan Alamat","has-counter":false,"data-testid":"input-address"},model:{value:(_vm.owner.address),callback:function ($$v) {_vm.$set(_vm.owner, "address", $$v)},expression:"owner.address"}})],1)]}}])})],1),_c('bg-grid-item',{staticClass:"mb-32",attrs:{"col":6}},[_c('ValidationProvider',{attrs:{"name":"Provinsi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('bg-field',{staticClass:"mb-0",attrs:{"label":"Provinsi","error":!!errors.length,"message":errors[0]}},[(_vm.loadingFetchData || _vm.area.loadingFetchProvince)?_c('bg-skeleton',{attrs:{"width":"100%","height":50}}):_c('bg-select',{attrs:{"placeholder":"Pilih Provinsi di sini","options":_vm.area.provinces,"data-testid":"input-province"},on:{"change":_vm.provinceChange},model:{value:(_vm.owner.province),callback:function ($$v) {_vm.$set(_vm.owner, "province", $$v)},expression:"owner.province"}})],1)]}}])})],1),_c('bg-grid-item',{staticClass:"mb-32",attrs:{"col":6}},[_c('ValidationProvider',{attrs:{"name":"Kota/Kabupaten","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('bg-field',{staticClass:"mb-0",attrs:{"label":"Kota/Kabupaten","error":!!errors.length,"message":errors[0]}},[(_vm.loadingFetchData || _vm.area.loadingFetchCity)?_c('bg-skeleton',{attrs:{"width":"100%","height":50}}):_c('bg-select',{attrs:{"placeholder":"Pilih Kota/Kabupaten di sini","disabled":!_vm.owner.province || !_vm.area.provinces.length,"options":_vm.area.cities,"data-testid":"input-city"},on:{"change":_vm.cityChange},model:{value:(_vm.owner.city),callback:function ($$v) {_vm.$set(_vm.owner, "city", $$v)},expression:"owner.city"}})],1)]}}])})],1),_c('bg-grid-item',{staticClass:"mb-32",attrs:{"col":6}},[_c('ValidationProvider',{attrs:{"name":"Kecamatan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('bg-field',{staticClass:"mb-0",attrs:{"label":"Kecamatan","error":!!errors.length,"message":errors[0]}},[(_vm.loadingFetchData || _vm.area.loadingFetchSubdistrict)?_c('bg-skeleton',{attrs:{"width":"100%","height":50}}):_c('bg-select',{attrs:{"placeholder":"Pilih Kecamatan di sini","disabled":!_vm.owner.city || !_vm.area.cities.length,"options":_vm.area.subdistricts,"data-testid":"input-subdistrict"},on:{"change":_vm.subdistrictChange},model:{value:(_vm.owner.subdistrict),callback:function ($$v) {_vm.$set(_vm.owner, "subdistrict", $$v)},expression:"owner.subdistrict"}})],1)]}}])})],1),_c('bg-grid-item',{staticClass:"mb-32",attrs:{"col":6}},[_c('ValidationProvider',{attrs:{"name":"Kelurahan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('bg-field',{staticClass:"mb-0",attrs:{"label":"Kelurahan","error":!!errors.length,"message":errors[0]}},[(_vm.loadingFetchData || _vm.area.loadingFetchVillage)?_c('bg-skeleton',{attrs:{"width":"100%","height":50}}):_c('bg-select',{attrs:{"placeholder":"Pilih kelurahan di sini","options":_vm.area.villages,"disabled":!_vm.owner.subdistrict || !_vm.area.subdistricts.length,"data-testid":"input-village"},model:{value:(_vm.owner.village),callback:function ($$v) {_vm.$set(_vm.owner, "village", $$v)},expression:"owner.village"}})],1)]}}])})],1)],1)],1),_c('bg-grid',{staticClass:"mt-32"},[_c('bg-grid-item',{attrs:{"col":12}},[_c('bg-button',{attrs:{"variant":"primary","disabled":_vm.loadingFetchData},on:{"click":_vm.checkingAllForm}},[_vm._v(" Simpan ")])],1)],1),_c('bg-modal',{attrs:{"desktop-size":"sm","title":"Yakin menyimpan perubahan?","description":"Mohon persiapkan dokumen pendukung yang dibutuhkan.","close-on-click-backdrop":!_vm.loadingSaveData,"data-testid":"modal-confirmation"},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"flex align-center justify-end"},[_c('bg-button',{staticClass:"mr-16",attrs:{"disabled":_vm.loadingSaveData},on:{"click":function($event){_vm.isConfirmModalOpen = false}}},[_vm._v(" Batal ")]),_c('bg-button',{attrs:{"variant":"primary","loading":_vm.loadingSaveData},on:{"click":_vm.handleSaveChanges}},[_vm._v(" Simpan ")])],1)]},proxy:true}]),model:{value:(_vm.isConfirmModalOpen),callback:function ($$v) {_vm.isConfirmModalOpen=$$v},expression:"isConfirmModalOpen"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }