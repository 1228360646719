import api from '@admin/api/service';

export default {
	updateOwnerProfile(propertyId, data) {
		return api.put(
			`/singgahsini/api/property/${propertyId}/owner-profile`,
			data
		);
	},

	getContractChangelog(propertyId, params) {
		return api.get(`/singgahsini/api/property/${propertyId}/changelog`, {
			params,
		});
	},
};
